<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">
        {{ title }} ({{ total }})
        <b-button
          @click="add()"
          class="is-primary is-pulled-right"
          icon-left="plus"
        >
          ເພີ່ມໃໝ່
        </b-button>
      </h1>
      <card-component class="has-table has-mobile-sort-spaced">
        <div class="card" style="padding: 1em">
          <b-field>
            <b-input
              v-model="keyword"
              placeholder="ຊອກຫາ ຕາມ ເບີໂທ, ຊື່"
              @keyup.native.enter="search"
            />
          </b-field>
        </div>
        <b-table
          :loading="isLoading"
          :paginated="perPage < total"
          :backend-pagination="perPage < total"
          backend-sorting
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          :data="models"
          :current-page.sync="currentPage"
          :pagination-rounded="true"
          @page-change="onPageChange"
          :total="total"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index+(currentPage>1?(perPage*currentPage-perPage):0)+1 }}
          </b-table-column>
          <b-table-column label="ເບີໂທ" field="tel" sortable v-slot="props">
            {{ props.row.tel }}
          </b-table-column>
          <b-table-column label="ຊື່" field="name" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column label="ແຂວງ" field="province" sortable v-slot="props">
            {{
              props.row.district && props.row.district.province
                ? props.row.district.province.name
                : ""
            }}
          </b-table-column>
          <b-table-column
            label="ເມືອງ"
            field="district"
            sortable
            v-slot="props"
          >
            {{ props.row.district ? props.row.district.name : "" }}
          </b-table-column>
          <b-table-column label="ບ້ານ" field="village" sortable v-slot="props">
            {{ props.row.village }}
          </b-table-column>
          <b-table-column label="ສະຖານະ" field="status" sortable v-slot="props">
            <span class="tag is-success" v-if="props.row.status == 'NORMAL'">
              ປົກກະຕິ
            </span>
            <span class="tag is-danger" v-if="props.row.status != 'NORMAL'">
              {{ props.row.status }}
            </span>
          </b-table-column>
          <b-table-column
            custom-key="actions"
            cell-class="is-actions-cell"
            v-slot="props"
          >
            <div class="buttons is-right">
              <b-tooltip
                v-if="props.row.isCod"
                label="ສ້າງບິນ"
                type="is-success"
                position="is-left"
              >
                <b-button
                  class="is-small is-success"
                  icon-left="view-dashboard"
                  @click="gotobatch(props.row)"
                >
                </b-button
              ></b-tooltip>
              <b-tooltip
                label="ເບີ່ງຂໍ້ມູນລູກຄ້າ"
                type="is-info"
                position="is-left"
              >
                <b-button
                  class="is-small is-info"
                  icon-left="eye"
                  @click="view(props.row.id)"
                >
                </b-button
              ></b-tooltip>
              <b-tooltip
                label="ແກ້ໄຂຂໍ້ມູນລູກຄ້າ"
                type="is-warning"
                position="is-left"
              >
                <b-button
                  class="is-small is-warning"
                  icon-left="pencil  "
                  @click="edit(props.row)"
                >
                </b-button
              ></b-tooltip>
              <b-tooltip
                label="ລຶບຂໍ້ມູນລູກຄ້າ"
                type="is-danger"
                position="is-left"
              >
                <b-button
                  class="is-small is-danger"
                  icon-left="trash-can"
                  @click="remove(props.row)"
                >
                </b-button
              ></b-tooltip>
            </div>
          </b-table-column>

          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </card-component>
    </section>

    <div class="modal is-active is-small" v-if="isShowRemove && model">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">ຢືນຢັນ</p>
          <button
            class="delete"
            aria-label="close"
            @click="isShowRemove = false"
          />
        </header>
        <section class="modal-card-body">
          ທ່ານຕ້ອງການລຶບແທ້ບໍ?
          <div>{{ model.code }} - {{ model.name }}, {{ model.tel }}</div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="isShowRemove = false">
            ບໍ່
          </button>
          <button class="button is-danger" @click="doremove()">ລຶບແທ້</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import CardComponent from "@/components/CardComponent";
import EmptyTable from "../components/EmptyTable.vue";

export default {
  name: "Customers",
  components: { CardComponent, EmptyTable },

  data() {
    return {
      currentPage: 1,
      perPage: 20,
      title: "ລູກຄ້າ",
      keyword: null,
      model: null,
      models: [],
      isLoading: false,
      isShowRemove: false,
      total: 0,
    };
  },
  mounted() {
    this.search();
  },
  watch: {
    models: function () {
      this.isLoading = false;
    },
  },
  methods: {
    gotobatch(item) {
      if (item.isCod)
        this.$router.push({ path: "/batchshipmentform/" + item.tel });
    },
    search() {
      if (this.keyword) {
        this.isLoading = true;
        const offset = (this.currentPage - 1) * this.perPage;
        Axios.get(
          `customers/search/${this.keyword}/${this.perPage}/${offset}`
        ).then((r) => {
          //console.log(r);
          this.models = r.models;
          this.total = r.total;
        });
      } else {
        this.get();
      }
    },
    onPageChange(page) {
      this.currentPage = page;
      this.search();
    },
    get() {
      this.isLoading = true;
      const offset = (this.currentPage - 1) * this.perPage;
      const url = `customers/gets/${this.perPage}/${offset}`;
      Axios.get(url).then((r) => {
        this.models = r.models;
        this.total = r.total;
      });
    },
    add() {
      this.$router.push({ path: "/customerForm/0" });
    },
    edit(item) {
      this.$router.push({ path: `/customerForm/${item.tel}` });
    },
    remove(item) {
      this.model = item;
      this.isShowRemove = true;
    },
    doremove() {
      Axios.delete("customers/" + this.model.id).then((r) => {
        //console.log(r);
        if (r) {
          this.$buefy.snackbar.open({
            type: "is-success",
            message: `ສຳເລັດ`,
            queue: true,
          });
        }

        this.search();
      });
      this.isShowRemove = false;
    },
    preview(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    view(id) {
      this.$router.push({ path: "/customer-info/" + id });
      //console.log(`view customer, ${id}`)
    },
  },
};
</script>

<style>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>